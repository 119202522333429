import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import i18next from 'i18next';
import { menuOpen, setMenu } from 'store/slices/menuSlice';
import { getCurrentUrl } from 'utils/functions/common';
import useMediaQuery from '@mui/material/useMediaQuery';

const NavItem = ({ item, level, indexParent }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.menuSlice);
    const { pathname } = useLocation();
    const currentLanguage = i18next.language;
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));

    const itemIcon =
        level > 1 ? (
            <FiberManualRecordIcon
                sx={{
                    width: customization.isOpen.includes(item?.menuUrl) ? 8 : 6,
                    height: customization.isOpen.includes(item?.menuUrl) ? 8 : 6
                }}
                fontSize={level > 0 ? 'inherit' : 'medium'}
            />
        ) : (
            <Avatar
                variant="rounded"
                alt="item_list"
                className="menu_icon"
                src={item?.attachment?.url && `${process.env.REACT_APP_EGOV_CONTEXT_URL}${item?.attachment?.url}`}
            />
        );

    const itemTarget = item.target ? '_blank' : '_self';
    const listItemProps = item.external
        ? { component: 'a', href: item.menuUrl, target: itemTarget }
        : { component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.menuUrl} target={itemTarget} />) };

    const itemHandler = (event) => {
        dispatch(menuOpen(item?.seq));
        if (matchesSM) {
            dispatch(setMenu(item?.seq));
        }
    };

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            sx={{
                borderRadius: theme.secondBorderRadius,
                py: level > 1 ? 1 : 1.25,
                pl: level > 1 ? `${level * 24}px` : '16px'
            }}
            className={`${getCurrentUrl().indexOf(item?.menuUrl) > -1 ? 'menu-selected' : 'false'} ${level > 1 && 'sub-selected-menu'}`}
            selected={getCurrentUrl().indexOf(item?.menuUrl) > -1}
            onClick={itemHandler}
        >
            <ListItemIcon>{itemIcon}</ListItemIcon>
            <ListItemText
                primary={<Typography variant="snb">{currentLanguage === 'kr' ? item.menuNameKr : item.menuName}</Typography>}
                secondary={
                    item.caption && (
                        <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                            {item.caption}
                        </Typography>
                    )
                }
            />
            {item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

export default NavItem;
