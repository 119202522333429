import { createApi } from '@reduxjs/toolkit/query/react';
import { Api } from 'api';
import { URL } from 'api/ApiUrls';

export const SponsorApi = createApi({
    reducerPath: 'SponsorApi',
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ['Sponsor'],
    endpoints: (build) => ({
        getSponsor: build.query({
            query: (params) => `${URL.SPONSOR_LIST}?${params}`,
            providesTags: ['LIST']
        }),
        getSponsorDetails: build.query({
            query: (params) => `${URL.SPONSOR_DETAIL}/${params}`,
            providesTags: ['DETAIL']
        }),
        createSponsor: build.mutation({
            query: (body) => ({
                url: `${URL.SPONSOR_CREATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['LIST']
        }),
        updateSponsor: build.mutation({
            query: (body) => ({
                url: `${URL.SPONSOR_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        }),
        deleteSponsor: build.mutation({
            query: (body) => ({
                url: `${URL.SPONSOR_DELETE}/${body}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['LIST']
        })
    })
});

export const {
    useGetSponsorQuery,
    useGetSponsorDetailsQuery,
    useCreateSponsorMutation,
    useUpdateSponsorMutation,
    useDeleteSponsorMutation
} = SponsorApi;
