import React, { lazy } from 'react';
import Loadable from 'components/loading/Loadable';
import { CommunityBoardApi } from 'api/community/CommunityBoardApi';
import { NewsProvider } from './context';

const List = Loadable(lazy(() => import('./list')));
const Create = Loadable(lazy(() => import('./create')));
const Edit = Loadable(lazy(() => import('./edit')));

const NewsBoardConfig = {
    routes: [
        {
            path: 'campaign',
            children: [
                {
                    path: '',
                    element: <NewsProvider><List /></NewsProvider>
                },
                {
                    path: 'create',
                    element: <NewsProvider><Create /></NewsProvider>
                },
                {
                    path: 'modify/:code',
                    element: <NewsProvider><Edit /></NewsProvider>
                }
            ]
        }
    ],
    API: CommunityBoardApi
};

export default NewsBoardConfig;
