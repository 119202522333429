import { createApi } from '@reduxjs/toolkit/query/react';
import { Api } from 'api';
import { URL } from 'api/ApiUrls';

export const CommonCodeFilterApi = createApi({
    reducerPath: 'CommonCodeFilterApi',
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ['CommonCodes'],
    endpoints: (build) => ({
        getCommonFilterCode: build.query({
            query: (codegroup) => `${URL.COMMONCODE_FILTER_LIST}?codegroup=${codegroup}`,
            providesTags: ['LIST']
        }),
        getCommonByGroupCodes: build.query({
            query: (codeGroups) => ({
                url: `${URL.COMMONCODE_FILTER_BY_GROUP_LIST}`,
                params: { codeGroups: codeGroups.join(',') },
                method: 'GET',
            }),
            providesTags: ['LIST']
        })
    })
});

export const { useGetCommonFilterCodeQuery, useGetCommonByGroupCodesQuery } = CommonCodeFilterApi;
