// material-ui
import { Button, Modal, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ModalInner } from './ModalInner';
import { ButtonWrapp } from 'components';
import { LoadingButton } from '@mui/lab';
import { IconCheck } from '@tabler/icons';

const ConfirmModal = ({ open, close, onClick, text, loading }) => {
    const { t } = useTranslation();

    return (
        <Modal open={open} onClose={close} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
            <ModalInner>
                <Typography id="parent-modal-description">{text}</Typography>
                <ButtonWrapp sx={{ marginTop: '20px' }}>
                    <LoadingButton
                        loading={loading}
                        disabled={loading}
                        size="small"
                        loadingPosition="start"
                        startIcon={<IconCheck />}
                        variant="contained"
                        onClick={onClick}
                        autoFocus
                    >
                        {t('act_confirm')}
                    </LoadingButton>

                    <Button disableElevation variant="outlined" color="primary" size="small" disabled={loading} onClick={close}>
                        {t('act_cancel')}
                    </Button>
                </ButtonWrapp>
            </ModalInner>
        </Modal>
    );
};

export default ConfirmModal;
