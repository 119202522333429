import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Select as BaseSelect, FormControl, FormHelperText, MenuItem, Skeleton } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';

import { useGetCommonFilterCodeQuery } from 'api/commonCodeApi';
import { useEffect } from 'react';
import { useState } from 'react';

//REACT HOOK FORM - COMMON CODE SELECT
const FormCmmCodeSelect = forwardRef(
    ({ seq, groupName, error, loading, disabled, label, defaultOption, all, helperText, customData, width,  ...props }, _) => {
        const { data, isLoading } = useGetCommonFilterCodeQuery(groupName);
        const [helperTextData, setHelperText] = useState(false);
        const { t, i18n } = useTranslation();

        useEffect(() => {
            setHelperText(helperText);
        }, [helperText]);

        if (isLoading || loading) return <Skeleton animation="wave" height={60} sx={{ borderRadius: '12px' }} />;

        return (
            <FormControl error={helperTextData ? true : false} size="medium" sx={{ width: width || '200px' }} disabled={disabled}>
                <BaseSelect
                    displayEmpty
                    size="small"
                    input={<OutlinedInput />}
                    // label={props.placeholder ?? ''}
                    value={props?.value ? props?.value : ''}
                    defaultValue={props?.value ? props?.value : ''}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 500 } } }}
                    {...props}
                >
                    <MenuItem key={'select-0'} value={''}>{(props?.defaulttext && props?.defaulttext) || t('All')}</MenuItem>
                    {(customData ?? data?.data)?.map((value, i) => (
                        <MenuItem key={i} value={seq ? value.seq : value.code}>
                            {i18n.language === 'kr' ? value.codeNm : value.codeEngNm}
                        </MenuItem>
                    ))}
                </BaseSelect>

                <FormHelperText error sx={{ ml: '0' }}>
                    {error}
                </FormHelperText>
            </FormControl>
        );
    }
);
export default FormCmmCodeSelect;
