import { CommonCodeFilterApi } from 'api/commonCodeApi';
import { DashboardInfoApi } from 'api/dashboard/DashboardApi';
import { AuthApi } from 'views/pages/authentication/auth-forms/AuthApi';
import {
    BannerConfig,
    PopupConfig,
    AdminConfig,
    FaqBoardConfig,
    QnaConfig,
    UserMenuConfig,
    CommonCodeConfig,
    SiteInfoConfig,
    SystemSettingConfig,
    RoleManageConfig,
    AdminMenuConfig,
    ContentMenuConfig,
    MailConfig,
    SendMailConfig,
    AccountSettingsConfig,
    MembershipConfig,
    ReviewConfig,
    NoticeBoardConfig,
    NewsBoardConfig,
    InquiryBoardConfig,
    StaffBoardConfig,
    PortfolioBoardConfig,
    CertBoardConfig,
    YoutubeBoardConfig,
    GalleryBoardConfig,
    ContactConfig,
    BoardMailConfig,
    SponsorConfig
} from 'views';

const Reducer = {
    [AuthApi.reducerPath]: AuthApi.reducer,
    [DashboardInfoApi.reducerPath]: DashboardInfoApi.reducer,
    [BannerConfig.API.reducerPath]: BannerConfig.API.reducer,
    [PopupConfig.API.reducerPath]: PopupConfig.API.reducer,
    [AdminConfig.API.reducerPath]: AdminConfig.API.reducer,
    [FaqBoardConfig.API.reducerPath]: FaqBoardConfig.API.reducer,
    [QnaConfig.API.reducerPath]: QnaConfig.API.reducer,
    [AdminMenuConfig.API.reducerPath]: AdminMenuConfig.API.reducer,
    [UserMenuConfig.API.reducerPath]: UserMenuConfig.API.reducer,
    [CommonCodeConfig.API.reducerPath]: CommonCodeConfig.API.reducer,
    [CommonCodeFilterApi.reducerPath]: CommonCodeFilterApi.reducer,
    [SiteInfoConfig.API.reducerPath]: SiteInfoConfig.API.reducer,
    [ContentMenuConfig.API.reducerPath]: ContentMenuConfig.API.reducer,
    [MailConfig.API.reducerPath]: MailConfig.API.reducer,
    [SendMailConfig.API.reducerPath]: SendMailConfig.API.reducer,
    [SystemSettingConfig.API.reducerPath]: SystemSettingConfig.API.reducer,
    [RoleManageConfig.API.reducerPath]: RoleManageConfig.API.reducer,
    [AccountSettingsConfig.API.reducerPath]: AccountSettingsConfig.API.reducer,
    [MembershipConfig.API.reducerPath]: MembershipConfig.API.reducer,
    [ReviewConfig.API.reducerPath]: ReviewConfig.API.reducer,
    [NoticeBoardConfig.API.reducerPath]: NoticeBoardConfig.API.reducer,
    [NewsBoardConfig.API.reducerPath]: NewsBoardConfig.API.reducer,
    [InquiryBoardConfig.API.reducerPath]: InquiryBoardConfig.API.reducer,
    [StaffBoardConfig.API.reducerPath]: StaffBoardConfig.API.reducer,
    [PortfolioBoardConfig.API.reducerPath]: PortfolioBoardConfig.API.reducer,
    [CertBoardConfig.API.reducerPath]: CertBoardConfig.API.reducer,
    [YoutubeBoardConfig.API.reducerPath]: YoutubeBoardConfig.API.reducer,
    [GalleryBoardConfig.API.reducerPath]: GalleryBoardConfig.API.reducer,
    [ContactConfig.API.reducerPath]: ContactConfig.API.reducer,
    [BoardMailConfig.API.reducerPath]: BoardMailConfig.API.reducer,
    [SponsorConfig.API.reducerPath]: SponsorConfig.API.reducer
};

export default Reducer;
