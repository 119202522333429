import React from 'react';
import { useTranslation } from 'react-i18next';
import UploadIcon from '@mui/icons-material/Upload';

const style = {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #5cbaff',
    padding: '5px 0',
    justifyContent: 'center',
    gap: '5px',
    cursor: 'pointer',
    color: '#5cbaff',
    borderRadius: '4px'
};

const UploadButton = ({ text }) => {
    const { t } = useTranslation();

    return (
        <div style={style}>
            <UploadIcon />
            <span>{t('upload')}</span>
        </div>
    );
};

export default UploadButton;
