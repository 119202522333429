export const SERVER_URL = process.env.REACT_APP_EGOV_CONTEXT_URL;
export const AVAIL_LANG = [
    { code: 'kr', name: '한국어' },
    { code: 'en', name: 'English' }
];
export const config = {
    basename: '/',
    defaultPath: '/',
    borderRadius: 12
};
