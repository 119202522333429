import React, { lazy } from 'react';
import Loadable from 'components/loading/Loadable';
import { SponsorApi } from 'api/sponsor/SponsorApi';
import { SponsorProvider } from './context';

const List = Loadable(lazy(() => import('./list')));
const Create = Loadable(lazy(() => import('./create')));
const Edit = Loadable(lazy(() => import('./edit')));

const SponsorConfig = {
    routes: [
        {
            path: '',
            children: [
                {
                    path: '',
                    element: (
                        <SponsorProvider>
                            <List />
                        </SponsorProvider>
                    )
                },
                {
                    path: 'create',
                    element: (
                        <SponsorProvider>
                            <Create />
                        </SponsorProvider>
                    )
                },
                {
                    path: 'modify/:code',
                    element: (
                        <SponsorProvider>
                            <Edit />
                        </SponsorProvider>
                    )
                }
            ]
        }
    ],
    API: SponsorApi
};

export default SponsorConfig;
