// REACT
import { useTranslation } from 'react-i18next';
import { useState, Fragment, useCallback } from 'react';

// FORM
import resultChecker from 'utils/functions/resultChecker';

// MUI
import {
    Box,
    Grid,
    Typography,
    Divider,
    Button,
    List,
    ListItem,
    IconButton,
    InputLabel,
    ListItemAvatar,
    Avatar,
    ListItemText
} from '@mui/material';
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import Face6TwoToneIcon from '@mui/icons-material/Face6TwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { useTheme } from '@mui/material/styles';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { blue, red } from '@mui/material/colors';

// COMPONENTS
import { DeleteConfirm } from 'components';
import { useSelector } from 'react-redux';

const Comments = ({
    commentData,
    isLoading,
    code,
    mutation,
    updateMutation,
    deleteMutation,
    contentDisabledData,
    listDisabledData,
    type
}) => {
    const { t } = useTranslation();
    let user = useSelector((state) => state.authentication.user);
    const theme = useTheme();
    const [deleteShow, setDeleteShow] = useState({ show: false, seq: null, createdBy: null });
    const [commentText, setCommentText] = useState('');
    const [commentFile, setCommentFile] = useState();
    const [commentId, setCommentId] = useState({ seq: null, createdBy: null });
    const [onDelete, deleteResult] = deleteMutation();

    const submit = (event) => {
        event.preventDefault();

        if (commentText) {
            let newData = {};

            if (type === 'Notice' || type === 'FreeBoard') {
                newData = {
                    boardSeq: code,
                    boardName: type,
                    text: commentText
                };
            }

            if (type === 'Project') {
                newData = {
                    projectSeq: code,
                    text: commentText
                };
            }

            let formData = new FormData();

            for (let key in newData) {
                formData.append(key, newData[key]);
            }

            if (commentFile?.type === 'local') {
                formData.append('files', commentFile?.data);
            }

            if (commentId?.seq) {
                formData.append('seq', commentId?.seq);
                formData.append('createdBy', commentId?.createdBy);
                updateMutation(formData)
                    .then((res) => {
                        resultChecker({ res, redirect: () => {}, type: 'update' });
                    })
                    .catch((err) => console.log('=====> ERROR', err));
            } else {
                mutation(formData)
                    .then((res) => {
                        resultChecker({ res, redirect: () => {}, type: 'insert' });
                    })
                    .catch((err) => console.log('=====> ERROR', err));
            }

            setCommentText('');
            setCommentFile();
            setCommentId();
        } else {
            console.log('===> not found');
        }
    };

    const handleDelete = useCallback(() => {
        let newData = {
            seq: deleteShow?.seq,
            createdBy: deleteShow?.createdBy
        };

        let formData = new FormData();

        for (let key in newData) {
            formData.append(key, newData[key]);
        }

        onDelete(formData)
            .then((res) => {
                resultChecker({ res, redirect: () => {}, type: 'delete' });
                setDeleteShow({ show: false, seq: null, createdBy: null }); // set Show to false on successful deletion
            })
            .catch((err) => console.log('useUpdateBannerMutation ERROR  => ', err));
    }, [onDelete, deleteShow]);

    return (
        <Box mt={5}>
            <Typography sx={{ mt: 2, fontSize: '20px' }}>
                {t('comment')} {commentData?.length}
            </Typography>
            <Grid item xs={12}>
                <Divider sx={{ flexGrow: 1, my: 2 }} orientation="horizontal" />
            </Grid>
            {contentDisabledData && (
                <form onSubmit={submit}>
                    <Grid container spacing={2}>
                        <Grid item xs={10.5}>
                            <Box sx={{ border: '1px solid #e3f2fd' }}>
                                <textarea
                                    id="content-flexible"
                                    name="content"
                                    onChange={(e) => setCommentText(e.target.value)}
                                    value={commentText}
                                    placeholder={t('project_comment_placeholder')}
                                    variant="standard"
                                    style={{
                                        borderRadius: '3px',
                                        padding: '10px',
                                        width: '100%',
                                        borderWidth: '0px',
                                        rounded: '24px',
                                        outline: '#c4c4c4',
                                        height: '107px'
                                    }}
                                />
                                <Box sx={{ background: '#f5f5f5', padding: '5px 10px', display: 'flex', alignItems: 'center' }}>
                                    <input
                                        id={'commentFileId'}
                                        name={'commentFileId'}
                                        type="file"
                                        onChange={(e) => setCommentFile({ type: 'local', data: e.target.files[0] })}
                                        style={{ display: 'none' }}
                                    />
                                    <InputLabel sx={{ cursor: 'pointer', mr: 1 }} htmlFor={'commentFileId'}>
                                        <AttachFileIcon fontSize="small" />
                                    </InputLabel>
                                    {commentFile?.data?.name && (
                                        <>
                                            {commentFile?.data?.name}
                                            <CloseIcon onClick={() => setCommentFile()} fontSize="14px" sx={{ cursor: 'pointer', ml: 1 }} />
                                        </>
                                    )}
                                    {commentFile?.data?.fileNm && commentFile?.data?.fileNm}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={1.5}>
                            <Button
                                variant="outlined"
                                type="submit"
                                sx={{
                                    height: '150px',
                                    width: '100%',
                                    alignItems: 'center',
                                    transition: 'all .2s ease-in-out',
                                    borderColor: theme.palette.primary.light,
                                    backgroundColor: theme.palette.primary.light,
                                    '&[aria-controls="menu-list-grow"], &:hover': {
                                        borderColor: theme.palette.primary.main,
                                        background: `${theme.palette.primary.main}!important`,
                                        color: theme.palette.primary.light,
                                        '& svg': {
                                            stroke: theme.palette.primary.light
                                        }
                                    },
                                    '& .MuiChip-label': {
                                        lineHeight: 0
                                    }
                                }}
                            >
                                {t('comment_send')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            )}

            <List>
                {commentData?.length > 0 &&
                    commentData?.map((item, index) => {
                        return (
                            <ListItem
                                key={index}
                                sx={{ borderBottom: '1px solid #0000001f' }}
                                secondaryAction={
                                    listDisabledData || user?.adminSeq === item?.createdBy.toString() ? (
                                        <Box>
                                            <IconButton
                                                onClick={() => {
                                                    setCommentText(item?.text);
                                                    setCommentId({ seq: item?.seq, createdBy: item?.createdBy });
                                                    setCommentFile({ type: 'service', data: item?.attachments[0] });
                                                }}
                                                edge="end"
                                                aria-label="edit"
                                            >
                                                <EditTwoToneIcon />
                                            </IconButton>

                                            <IconButton
                                                edge="end"
                                                sx={{ marginLeft: 2 }}
                                                aria-label="delete"
                                                onClick={() => setDeleteShow({ show: true, seq: item?.seq, createdBy: item?.createdBy })}
                                            >
                                                <DeleteTwoToneIcon sx={{ color: red[400] }} />
                                            </IconButton>
                                        </Box>
                                    ) : (
                                        ''
                                    )
                                }
                            >
                                <ListItemAvatar>
                                    <Avatar>
                                        <Face6TwoToneIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                            <Typography component="span" fontWeight={600} marginRight={1}>
                                                {item?.createdUsername}
                                            </Typography>
                                            <Typography component="span" fontSize={'12px'}>
                                                {item?.formattedCreateDate}
                                            </Typography>
                                        </Box>
                                    }
                                    secondaryTypographyProps={{ component: 'div' }}
                                    secondary={
                                        <Fragment>
                                            <Typography component="span" marginY={1} whiteSpace={'break-spaces'}>
                                                {item?.text || ''}
                                            </Typography>

                                            {item?.attachments && item?.attachments?.[0] && (
                                                <Fragment>
                                                    <Box display={'flex'} flexDirection={'row'}>
                                                        <ImageTwoToneIcon fontSize="small" sx={{ color: blue[400], marginLeft: '-2px' }} />
                                                        <Typography
                                                            component="a"
                                                            target="_blank"
                                                            marginLeft={1}
                                                            color={'#9e9e9e'}
                                                            href={`${process.env.REACT_APP_EGOV_CONTEXT_URL}${item?.attachments?.[0]?.url}`}
                                                        >
                                                            {item?.attachments?.[0]?.fileNm}
                                                        </Typography>
                                                    </Box>
                                                </Fragment>
                                            )}
                                        </Fragment>
                                    }
                                />
                            </ListItem>
                        );
                    })}
            </List>
            {deleteShow?.show && (
                <DeleteConfirm
                    show={deleteShow?.show}
                    setShow={() => setDeleteShow({ show: false })}
                    isLoading={isLoading || deleteResult?.isLoading}
                    onDelete={handleDelete}
                    code={code}
                />
            )}
        </Box>
    );
};

export default Comments;
