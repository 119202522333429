import { useTranslation } from 'react-i18next';
import { CircularProgress, Button, styled } from '@mui/material';
import { SaveAsOutlined } from '@mui/icons-material';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import ConfirmModal from 'components/Modal/ConfirmModal';

const BaseButton = styled(Button)(({ theme }) => ({
    backgroundImage: theme.palette.primary.button,
    ...theme.typography.actionBottom
}));

const SaveButton = ({ loading, isValid, ...props }) => {
    const { t } = useTranslation();

    return (
        <>
            <BaseButton
                type="button"
                disabled={loading}
                startIcon={loading ? <CircularProgress size={20} color="light" /> : <SaveAsOutlined />}
                {...props}
            >
                {t('act_save')}
            </BaseButton>
        </>
    );
};
export default SaveButton;
