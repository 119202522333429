export default function componentStyleOverrides(theme) {
    const bgColor = theme.colors?.grey50;
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    borderRadius: theme.borderRadius
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'none'
                },
                rounded: {
                    borderRadius: theme?.borderRadius
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: theme.colors?.textDark,
                    padding: '24px'
                },
                title: {
                    fontSize: '1.125rem'
                }
            }
        },
        MuiTreeItem: {
            styleOverrides: {}
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    '& .MuiListItemButton-root': {
                        fontSize: '0.875rem',
                        marginBottom: 0.5,
                        borderRadius: theme.borderRadius,
                        color: theme.colors.darkTextHint2,
                        padding: '0.675rem 0.8rem 0.675rem 1rem',
                        display: 'flex',
                        alignItems: 'center',
                        textTransform: 'capitalize'
                    },
                    '& .menu_icon': {
                        backgroundColor: theme.colors.paper,
                        width: '2rem',
                        height: '2rem',
                        boxShadow: theme.boxBorderShadow,
                        placeItems: 'center',
                        '& img': {
                            width: '18px',
                            height: '18px'
                        }
                    },
                    '& .MuiListItemIcon-root': {
                        minWidth: 'auto'
                    },
                    '& .MuiListItemText-root': {
                        color: theme.colors?.darkTextHint1,
                        margin: 'auto 0px auto  0.6rem'
                    },
                    '& .MuiTypography-h5': {
                        color: theme.colors.darkTextHint2,
                        textTransform: 'capitalize',
                        fontWeight: 400,
                        margin: '0px'
                    },
                    '&.menu-selected': {
                        '& .MuiTypography-root': {
                            fontWeight: 600
                        },
                        '& .MuiAvatar-root': {
                            backgroundColor: theme.colors.darkPrimaryLight
                        }
                    },
                    '&.Mui-selected': {
                        boxShadow: theme.boxBoxShadow,
                        backgroundColor: theme.colors.paper,
                        '&:hover': {
                            backgroundColor: theme.colors.paper,
                            boxShadow: theme.boxBoxShadow
                        },
                        '& .MuiTypography-root': {
                            fontWeight: 600
                        },
                        '& .MuiAvatar-root': {
                            backgroundColor: theme.colors.darkPrimaryLight
                        }
                    },
                    '&:hover': {
                        backgroundColor: theme.colors.paper,
                        color: theme.colors?.darkTextHint1,
                        fontWeight: 600,
                        '& .MuiListItemIcon-root': {
                            color: theme.colors?.darkTextHint1
                        }
                    }
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    minWidth: '36px'
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: theme.textDark
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: theme.textDark,
                    '&::placeholder': {
                        color: theme.darkTextSecondary,
                        fontSize: '0.875rem'
                    }
                },
                muiInput: {
                    root: {
                        background: bgColor,
                        padding: '1rem',
                        borderRadius: theme?.borderRadius,
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.colors?.grey400
                        },
                        '&:hover $notchedOutline': {
                            borderColor: theme.colors?.primaryLight
                        },
                        '&.MuiInputBase-multiline': {
                            padding: 1
                        }
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: bgColor,
                    borderRadius: theme?.borderRadius,
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.colors?.grey400
                    },
                    '&:hover $notchedOutline': {
                        borderColor: theme.colors?.primaryLight
                    },
                    '&.MuiInputBase-multiline': {
                        padding: 1
                    }
                },
                input: {
                    fontWeight: 500,
                    background: bgColor,
                    padding: '15.5px 14px',
                    borderRadius: theme?.borderRadius,
                    '&.MuiInputBase-inputSizeSmall': {
                        padding: '10px 14px',
                        '&.MuiInputBase-inputAdornedStart': {
                            paddingLeft: 0
                        }
                    }
                },
                inputAdornedStart: {
                    paddingLeft: 4
                },
                notchedOutline: {
                    borderRadius: theme?.borderRadius
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: theme.colors?.grey300
                    }
                },
                mark: {
                    backgroundColor: theme.paper,
                    width: '4px'
                },
                valueLabel: {
                    color: theme?.colors?.primaryLight
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.divider,
                    opacity: 1
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: theme.colors?.primaryDark,
                    background: theme.colors?.primary200,
                    borderRadius: theme.borderRadius
                }
            }
        },
        // MuiChip: {
        //     styleOverrides: {
        //         root: {
        //             '.MuiChip-root': {
        //                 borderRadius: theme.borderRadius
        //             }
        //         }
        //     }
        // },
        MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-deletable .MuiChip-deleteIcon': {
                        color: 'inherit'
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme.paper,
                    background: theme.colors?.grey700
                }
            }
        }
    };
}
